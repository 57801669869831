import Image from "next/image";
import { useWindowSize } from "react-use";

function Background() {
  const { width = 1, height = 1 } = useWindowSize();

  return (
    <div className="absolute left-0 top-0 -z-10 h-full w-screen overflow-hidden">
      <Image
        src="/landing/blurry_stars.svg"
        alt="Background stars"
        width={width === Infinity ? 1 : width}
        height={height === Infinity ? 1 : height}
        className="absolute top-[100vh] -z-10 h-auto w-[100vw] min-w-[50vw] object-cover"
      />
      <Image
        src="/landing/prefold_bg_left.svg"
        alt="Background article"
        width={width === Infinity ? 1 : width / 2}
        height={height === Infinity ? 1 : height}
        className="absolute right-1/2 top-0 -z-10 h-screen w-auto min-w-[50vw] object-cover"
      />
      <Image
        src="/landing/prefold_bg_right.svg"
        alt="Background article"
        width={width === Infinity ? 1 : width / 2}
        height={height === Infinity ? 1 : height}
        className="absolute left-[65%] top-0 -z-10 h-screen w-auto min-w-[50vw] object-cover"
      />
    </div>
  );
}

export default Background;
