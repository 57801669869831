import { useState, FormEvent } from "react";
import Image from "next/image";
import { toast } from "react-toastify";
import { quando } from "config/fonts";

import BasicToast from "components/common/Layout/BasicToast";
import { TOAST_IDS } from "constants/toastConstants";
import { validateEmail } from "utils/textUtils";
import { addToWaitlist } from "pages/api/user";
import Link from "next/link";

function CloseCTA() {
  const [showEmailInput, setShowEmailInput] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const handleGetAccessClick = () => setShowEmailInput(true);

  const handleEmailChange = (e: FormEvent<HTMLInputElement>) =>
    setEmail(e.currentTarget.value);

  // WAITLIST DISABLED
  // const handleSendToWaitlist = async () => {
  //   if (!validateEmail(email)) {
  //     toast.error("Please enter a valid email address", {
  //       containerId: TOAST_IDS.basicToastId,
  //     });
  //   } else {
  //     const waitlistUser = await addToWaitlist(email);

  //     toast.success(
  //       `You're on the waitlist! Position: ${waitlistUser.priority}`,
  //       {
  //         containerId: TOAST_IDS.basicToastId,
  //       }
  //     );
  //   }
  // };

  // {showEmailInput ? (
  //           <div className="mt-8 flex">
  //             <input
  //               id="email"
  //               name="email"
  //               type="email"
  //               autoComplete="email"
  //               required
  //               className="block w-full appearance-none rounded-l-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
  //               onChange={handleEmailChange}
  //             />
  //             <button
  //               onClick={handleSendToWaitlist}
  //               disabled={!email}
  //               type="button"
  //               className="inline-flex items-center rounded-r-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-orange-600 disabled:opacity-70"
  //             >
  //               Submit
  //             </button>
  //           </div>
  //         ) : (
  //     <button
  //       onClick={handleGetAccessClick}
  //       type="button"
  //       className="mt-14 flex w-auto flex-wrap content-center items-center justify-center rounded-full border border-transparent bg-orange-600  py-2 px-10 text-sm font-medium text-white shadow-sm transition-all hover:-translate-y-1  hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-base"
  //     >
  //       <Image
  //         className="mr-2 h-4 w-auto"
  //         src="/images/starlo_stars_white.svg"
  //         alt="Starlo Stars"
  //         width={20}
  //         height={20}
  //         priority
  //       />
  //       Get Early Access
  //     </button>)}

  return (
    <div className="mt-32 flex flex-col items-center justify-center px-5 text-center">
      <h3 className={`${quando.className} text-2xl sm:text-[4vw]`}>
        Discover a new way to post.
      </h3>
      <h4 className="mt-10 text-base font-normal sm:text-lg md:text-xl">
        The hero of the story should be you, not the platform.
      </h4>

      <Link
        href="/register"
        className="mt-14 flex w-auto flex-wrap content-center items-center justify-center rounded-full border border-transparent bg-orange-600  px-10 py-2 text-sm font-medium text-white shadow-sm transition-all hover:-translate-y-1  hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-base"
      >
        <Image
          className="m-1 mr-2 h-4 w-auto"
          src="/images/starlo_stars_white.svg"
          alt="Starlo Stars"
          width={20}
          height={20}
          priority
        />
        Get Early Access
      </Link>

      <BasicToast enableMultiToast={true} />
    </div>
  );
}

export default CloseCTA;
