import { useState, FormEvent } from "react";
import Image from "next/image";
import { useWindowSize } from "react-use";
import { toast } from "react-toastify";
import Link from "next/link";

import { quando } from "config/fonts";
import BasicToast from "components/common/Layout/BasicToast";
import { TOAST_IDS } from "constants/toastConstants";
import { validateEmail } from "utils/textUtils";
import { addToWaitlist } from "pages/api/user";

function PreFold() {
  const [showEmailInput, setShowEmailInput] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const { width } = useWindowSize();

  const getPortraitVideoWidth = () => {
    if (width < 400) {
      return 140;
    } else if (width < 640) {
      return 205;
    }
    return 384;
  };

  const getPortraitVideoHeight = () => {
    if (width < 400) {
      return 318;
    } else if (width < 640) {
      return 376;
    }
    return 705;
  };

  const handleGetAccessClick = () => setShowEmailInput(true);

  const handleEmailChange = (e: FormEvent<HTMLInputElement>) =>
    setEmail(e.currentTarget.value);

  // WAITLIST DISABLED
  // const handleSendToWaitlist = async () => {
  //   if (!validateEmail(email)) {
  //     toast.error("Please enter a valid email address", {
  //       containerId: TOAST_IDS.basicToastId,
  //     });
  //   } else {
  //     const waitlistUser = await addToWaitlist(email);

  //     toast.success(
  //       `You're on the waitlist! Position: ${waitlistUser.priority}`,
  //       {
  //         containerId: TOAST_IDS.basicToastId,
  //       }
  //     );
  //   }
  // };

  // {showEmailInput ? (
  //           <div className="mt-8 flex">
  //             <input
  //               id="email"
  //               name="email"
  //               type="email"
  //               autoComplete="email"
  //               required
  //               className="block w-full appearance-none rounded-l-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
  //               onChange={handleEmailChange}
  //             />
  //             <button
  //               onClick={handleSendToWaitlist}
  //               disabled={!email}
  //               type="button"
  //               className="inline-flex items-center rounded-r-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-orange-600 disabled:opacity-70"
  //             >
  //               Submit
  //             </button>
  //           </div>
  //         ) : (
  //           <button
  //             onClick={handleGetAccessClick}
  //             type="button"
  //             className="mt-8 flex w-auto flex-wrap content-center items-center justify-center rounded-full border border-transparent bg-orange-600 py-2  px-10 text-sm font-medium text-white shadow-sm transition-all hover:-translate-y-1  hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-base"
  //           >
  //             <Image
  //               className="mr-2 h-4 w-auto"
  //               src="/images/starlo_stars_white.svg"
  //               alt="Starlo Stars"
  //               width={20}
  //               height={20}
  //               priority
  //             />
  //             Get Early Access
  //           </button>
  //         )}

  return (
    <div className="relative isolate mt-[80px] overflow-visible bg-transparent pt-16 sm:pt-40">
      <div className="mx-auto flex flex-col justify-center px-5 text-center">
        <h1
          className={`${quando.className} font-bold tracking-tight text-[4xl] text-gray-900 sm:text-[4vw] `}
        >
          Your personal social profile
        </h1>
        <h2 className="text-md mt-8 font-normal  leading-8 text-gray-800 sm:mt-12 sm:text-lg md:text-xl">
          <span className="mt-1 sm:mt-2 sm:inline-block">
          Starlo is a personal website that works just like your favorite social app.
          </span>
        </h2>
        <div className="flex justify-center">
          <Link
            href="/register"
            className="mt-10 flex w-auto flex-wrap content-center items-center justify-center rounded-full border border-transparent bg-orange-600 py-2  px-10 text-sm font-medium text-white shadow-sm transition-all hover:-translate-y-1  hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-base"
          >
            <Image
              className="my-1 mr-2 h-4 w-auto"
              src="/images/starlo_stars_white.svg"
              alt="Starlo Stars"
              width={20}
              height={20}
              priority
            />
            Get Early Access
          </Link>
        </div>
      </div>

      <div className="relative mb-[0px]  mt-10 w-[calc(100vw-(100vw-100%))] -translate-x-[1%] -translate-y-[5%] sm:mb-[0px] sm:mt-8  md:mb-[0px]">
        <Image
          className="absolute left-[90%] top-[45%] -translate-x-1/2 scale-95 sm:left-[80%] sm:scale-100 md:left-[71%] md:top-[32%]"
          src="/landing/star_right.svg"
          alt="Orange Starlo stars"
          width={25}
          height={25}
        />
        <Image
          className="absolute left-[15%] top-[50%] -translate-x-1/2 scale-95 sm:left-[15%] sm:scale-100 md:left-[20%] md:top-[40%] lg:left-[30%]"
          src="/landing/star_left.svg"
          alt="Orange Starlo stars"
          width={30}
          height={30}
        />
        <Image
          className="absolute left-1/2 -bottom-[7%] -translate-x-1/2 scale-95  sm:scale-100 "
          src="/landing/star_bottom.svg"
          alt="Orange Starlo stars"
          width={20}
          height={20}
        />
        <Image
          className="absolute left-1/4 top-[60%] -translate-x-1/2 -translate-y-1/2 scale-[40%] select-none sm:top-[70%] sm:scale-[60%] md:top-[70%] md:scale-75"
          src="/landing/example_audio.png"
          alt="Example audio"
          width={512}
          height={616}
        />
        <Image
          className="absolute left-1/4 top-[2%] -translate-x-1/2 scale-50 select-none sm:scale-75 "
          src="/landing/example_article.png"
          alt="Example article"
          width={512}
          height={354}
          priority
        />
        <Image
          className="absolute left-3/4 top-[70%] -translate-x-1/2 -translate-y-1/2 scale-[40%] select-none sm:top-[70%] sm:scale-[60%] md:top-[80%] md:scale-75"
          src="/landing/example_images.png"
          alt="Example images"
          width={512}
          height={512}
        />
        <Image
          className="absolute left-3/4 -top-[3%] -translate-x-1/2 scale-50 select-none sm:scale-75 "
          src="/landing/example_landscape_video.png"
          alt="Example landscape video"
          width={512}
          height={384}
          priority
        />
        <Image
          className="relative left-1/2 max-w-[50vw] -translate-x-1/2 select-none pt-[20%] sm:pt-[10%]   "
          src="/landing/example_portrait_video.svg"
          alt="Example portrait video"
          width={getPortraitVideoWidth()}
          height={getPortraitVideoHeight()}
          priority
        />
        <Image
          className="absolute left-[90%] top-[50%] -translate-x-1/2 -translate-y-1/2 scale-[60%] select-none sm:left-[85%] sm:scale-90 md:left-[80%] md:top-[45%]"
          src="/landing/example_text_post.png"
          alt="Example post"
          width={512}
          height={302}
        />
      </div>
      <BasicToast enableMultiToast={true} />
    </div>
  );
}

export default PreFold;
