import Link from "next/link";
import { LANDING_BOTTOM_NAVIGATION_OPTIONS } from "./constants";

function Footer() {
  return (
    <footer className=" mt-20 flex w-screen items-center  justify-center border-t border-gray-200 px-10 py-20 sm:mt-28  sm:py-28 ">
      <div className="flex w-full max-w-6xl flex-col items-center justify-between sm:flex-row sm:items-center">
        <p className=" text-sm leading-5 text-gray-500  md:mt-0">
          &copy; <Link href="/all_profiles">Starlo</Link>,{" "}
          <Link href="/all_posts">LLC</Link> {`${new Date().getFullYear()}`}
        </p>
        <nav className="mt-8 flex flex-col items-center justify-center sm:mt-0 sm:flex-row sm:gap-y-8">
          {LANDING_BOTTOM_NAVIGATION_OPTIONS.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={
                "rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100"
              }
            >
              {item.name}
            </a>
          ))}
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
