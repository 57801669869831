import Script from "next/script";

import Navbar from "components/Landing/Navbar";
import PreFold from "components/Landing/Prefold";
import Background from "components/Landing/Background";
import About from "components/Landing/About";
import CloseCTA from "components/Landing/CloseCTA";
import Footer from "components/Landing/Footer";

const LandingPage = () => {
  return (
    <>
      <main className="relative overflow-x-hidden ">
        
        <Background />
        <PreFold />
        <Navbar />
        <About />
        <CloseCTA />
        <Footer />
      </main>
    </>
  );
};

export default LandingPage;
