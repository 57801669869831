import Image from "next/image";
import { quando } from "config/fonts";

import RevenueCalculator from "./RevenueCalculator";

function About() {
  return (
    <div
      className="flex w-[calc(100vw-(100vw-100%))] flex-col items-center justify-center overflow-hidden px-5 pb-20 pt-32 sm:px-8 md:px-16"
      id="about"
    >
      <h3
        className={`${quando.className} text-md max-w-3xl text-center sm:text-2xl md:text-3xl`}
      >
        Post in a space all your own.
      </h3>

      <div className="">
        <div className="mt-32 grid grid-cols-1 gap-x-2 gap-y-6 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-10 md:gap-x-10">
          <Image
            className=""
            src="/landing/example_profile_snapshot.png"
            alt="Profile snapshot"
            width={2244}
            height={1672}
          />
          <div className="items-left flex flex-col justify-center">
            <h4 className=" max-w-md text-xl sm:max-w-lg lg:text-2xl xl:text-3xl">
              <div>
                <span className="text-[#FF0000]">You</span>
                <span className="text-[#1DA1F2]">Tw</span>
                <span className="text-black">ik</span>
                <span className="bg-gradient-to-r from-[#833AB4] to-[#F56040] bg-clip-text text-transparent">
                  stagram
                </span>{" "}
                isn&#39;t the main character.
              </div>
              <div className="mt-2 w-fit  bg-orange-600 px-2 py-1 text-white md:px-3 md:py-2">
                You are.
              </div>
            </h4>
            <div className="mt-5 max-w-md text-sm sm:max-w-lg sm:text-base md:text-lg ">
              <div>
                We’re on a mission to democratize posting tools for creators.
                <br />
                <br />
                For far too long we’ve dealt with frustrating redesigns,
                annoying algorithm changes, and platforms that are not aligned
                with the interests of the users.
                <br />
                <br />
                We believe everyone should be able to build their portfolio,
                profile, or home outside of a social network.
              </div>
            </div>
          </div>
        </div>

        <div className="mt-24 grid grid-cols-1 gap-x-2  gap-y-6 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-10 md:gap-x-10">
          <Image
            className=""
            src="/landing/post_snapshot.png"
            alt="Post snapshot"
            width={2244}
            height={1383}
          />
          <div className="items-left flex flex-col justify-center text-left">
            <h4 className="max-w-lg text-left text-xl lg:text-2xl xl:text-3xl">
              Simply create. We&#39;ll handle the rest.
            </h4>
            <div className="mt-5 max-w-md text-sm sm:max-w-lg sm:text-base md:text-lg ">
              <div>
                Easily post anything. Text, photo, video, audio, and more,
                formatted like a social network, comments and all.
              </div>
            </div>
          </div>
        </div>

        {/* <RevenueCalculator /> */}
      </div>
    </div>
  );
}

export default About;
